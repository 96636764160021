.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.contact-form {
  /* width: 40vw; */
  margin: 5rem 2rem;
}

.contact-list {
  margin: 5rem 2rem;
}

a:has(.contact-card-link) {
  padding: 0rem 1rem;
}

@media only screen and (max-width: 600px) {
  .contact-box {
    flex-direction: column;
  }
  a:has(.contact-card-link) {
    padding: 1rem 1rem;
  }
}

@font-face {
  font-family: "ms_sans_serif";
  src: url("../public/W95FA.otf");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "ms_sans_serif_bold";
  src: url("../public/W95FA.otf");
  font-weight: bold;
  font-style: normal;
}
body,
input,
select,
textarea {
  font-family: ms_sans_serif !important;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
